import React, { useRef } from 'react';
import { graphql, Link as GLink } from 'gatsby';

import {
  Heading, Box, Divider, OrderedList, ListItem, Text, Center, Button, Flex, Link, Stack,
} from '@chakra-ui/react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import Content from '../../components/content';
import AsideBox from '../../components/AsideBox';

import { workAheadStyles } from '../../styles';
import tracks from '../../images/tracks.png';

const Article = ({ data, location }) => {
  const content = {
    title: 'How to choose the right job',
    heading: 'How to choose the right job',
    intro: 'Use our 3 step decision criteria to understand where to go, and what to do with your life next.',
    subheading: 'What matters when choosing the right job',
  };

  const layoutRef = useRef();

  return (
    <Layout title={content.title} description={content.intro} ref={layoutRef} image={tracks} location={location}>
      <Heading as="h1" size="3xl" fontWeight="100" mb="4">{content.heading}</Heading>
      <Flex dir="row" mb="8">
        <Text fontSize="xl">{content.intro}</Text>
      </Flex>
      <Box
        py="8"
        sx={{ ...workAheadStyles.fullWidthBackground }}
        height={{ base: 100, md: 200 }}
        bgImage={`url(${tracks})`}
        bgPosition="center left"
        bgSize="cover"
        bgRepeat="no-repeat"
      />
      <Box bg="whiteAlpha.600" p={{ base: 8, md: 16 }} position="relative">
        <Text
          fontSize="sm"
          position="absolute"
          top="0"
          right="0"
          mt={{ base: 2, md: 8 }}
          mr={{ base: 2, md: 8 }}
        >Posted June 03, 2021
        </Text>
        <Box maxW="2xl" mb="8">
          <Heading size="lg" mb="4">{content.subheading}</Heading>
          <Text>
            <OrderedList>
              <ListItem> <Link href="#how-we-think-about-jobs">How we think about jobs</Link></ListItem>
              <ListItem> <Link href="#occupational-stability">Occupational Stability</Link></ListItem>
              <ListItem> <Link href="#wages+quality-of-life">Wages &amp; Quality of Life</Link></ListItem>
              <ListItem> <Link href="#job-flexibility">Job Flexibility</Link></ListItem>
            </OrderedList>
          </Text>
        </Box>

        <Divider mb="8" />

        <Box mb="8" maxW="xl">
          <Heading as="h2" size="md">How we think about jobs</Heading>
          <a id="how-we-think-about-jobs" />
          <Text>At Work ahead, we believe you should have the tools to make informed professional decisions. Changing jobs is a personal decision, and can come with a lot of baggage. In our research with potential job seekers, we have found that people can be resistant to change, even if their lives could be better by the change. <Link as={GLink} to="/calculator">Weighing the costs and benefits of a new job</Link> can feel like an unnecessarily hard decision. To make it easier, we created 3 decision criteria: Occupational Stability, Wages & Quality of Life, and Job Flexibility. Depending on where you are in life, one or all of these categories may be relevant to you.</Text>
        </Box>
        <Box mb="8" maxW="xl">
          <Heading as="h3" size="md">Occupational Stability</Heading>
          <a id="occupational-stability" />
          <Text>Occupational Stability gives you an idea if a job is growing or shrinking relative to the industry and area you work in. We looked at the larger trends in the workforce and the world to understand how jobs are changing, and compared them to current metrics on the distribution of jobs over the US.</Text>
          <Text>Is your job safe from automation -- will it exist in 10 years? If you are looking to make a career change, are you moving into a growing industry? If you are looking to learn a new skill, is it one that will be in demand?</Text>
        </Box>
        <Box mb="10" py="16" sx={{ ...workAheadStyles.fullWidthBackground }} bg="white">
          <Heading as="h3" size="sm" mb="4">Is your profession stable enough to get you to retirement?</Heading>
          <Flex>
            <Box maxWidth="50%">
              <Text>We pulled together the factors that influence future job prospects to give you a holistic view of the risks you may face along the path to retirement.</Text>
            </Box>
            <Center flex="1">
              <Button as={GLink} to="/job-stability/calculator" variant="outline" colorScheme="black" size="lg">Start planning</Button>
            </Center>
          </Flex>
        </Box>
        <Box mb="8" maxW="xl">
          <a id="wages+quality-of-life" />
          <Heading as="h3" size="md">Wages &amp; Quality of Life</Heading>
          <Text>Probably the most common consideration looking for work, how much does it pay! While pay is important, you should consider it in terms of how the money you make helps you make the most out of life. We look at the wages you could make in a location, and compare them to average wages in that area, and national wages for the same occupation. We then compare that with cost of living information in your area to determine how much you will have left over at the end of the month.</Text>
          <Text>We all want to make more money, how realistic is that in your current profession? What is your current monthly cost of living? How do you compare to your location's average?</Text>
        </Box>
        <Box mb="8" maxW="xl">
          <a id="job-flexibility" />
          <Heading as="h3" size="md">Job Flexibility</Heading>
          <Text>Job Flexibility can mean many things to many people: The ability to work from home or work remote. Having accommodatable working hours or a flexible schedule.  We compare occupation and industry with trends in the larger workforce to determine where job flexibility is growing. Basically we try to answer the question: In what fields are you most likely to find a flexible job.</Text>
          <Text>What are your personal needs for job flexibility? What skills do you have that could best be used in a flexible work environment? Are you open to learning new skills if they would help you get that flexible job?</Text>
        </Box>
        <Box mb="8" maxW="xl">
          <Heading as="h3" size="md">What’s next</Heading>
          <Text>Finding the right job can feel like a burden to get right. Understanding what is most important to you when looking for a new job can help clarify what can otherwise be a hard decision process.</Text>
          <Text>Our calculators can help you understand where you are now, and plan for where you want to go next.</Text>
          <Button as={GLink} to="/calculator" colorScheme="orange" mt="8">Take your Next Step</Button>
        </Box>
      </Box>

      <Text fontSize="sm" float="right" mt="8">
        Cover photo by <Link to="https://unsplash.com/@eberhardgross" isExternal>Jeremy Bishop on Unsplash</Link>
      </Text>

    </Layout>
  );
};

export default Article;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
